import { defaultTo, omit } from "lodash";
import React, { forwardRef } from "react";
import { DeleteIcon } from "../../icons/DeleteIcon";
import type { ButtonProps } from "./Button";
import { Button } from "./Button";

export type DeleteButtonProps = Omit<ButtonProps, "kind"> & {
  kind?: "danger" | "delete" | "tertiaryDestructive";
  showIcon?: boolean;
};

export const DeleteButton = forwardRef<HTMLButtonElement, DeleteButtonProps>((props, ref) => {
  const kind = !props.kind ? "delete" : props.kind;

  return (
    <Button kind={kind} {...omit(props, ["kind"])} ref={ref} startEnhancer={defaultTo(props.showIcon, true) ? <DeleteIcon /> : null}>
      {props.children}
    </Button>
  );
});
