import { mergeOverrides } from "baseui";
import type { SharedProps } from "baseui/input";
import type { Override, Overrides } from "baseui/overrides";
import type { TextareaProps as BaseUITextareaProps } from "baseui/textarea";
import { Textarea as BaseUITextArea } from "baseui/textarea";
import React, { forwardRef } from "react";
import { expandBorderWidth, expandPadding } from "../../utils";
import { useWidgets } from "../contexts";

export type TextareaProps = Omit<BaseUITextareaProps, "overrides"> & {
  readonly?: boolean;
  dataTestId?: string;
  spellcheck?: boolean;
  overrides?: {
    Root?: Override<SharedProps>;
    InputContainer?: Override<SharedProps>;
    Input?: Override<SharedProps>;
    Before?: Override<SharedProps>;
    After?: Override<SharedProps>;
  };
};

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const { disableInputs, readonlyInputs } = useWidgets();

  const disabled = props.disabled ?? disableInputs?.(props);
  const readonly = props.readonly ?? readonlyInputs?.(props);

  const overrides = mergeOverrides(
    {
      Root: {
        style: ({ $theme, $isFocused, $disabled }) => ({
          ...expandBorderWidth("1px"),
          ...expandPadding(0),
          backgroundColor:
            readonly || $disabled ? $theme.colors.alpha50 : $isFocused ? $theme.colors.backgroundPrimary : $theme.colors.inputAlpha,
          boxShadow: $disabled ? undefined : $isFocused ? `inset 0 0 0 1px ${$theme.colors.borderFocus}` : $theme.lighting.shadowInputInset,
          [":hover"]: {
            borderColor: $disabled ? undefined : $isFocused ? $theme.colors.borderFocus : $theme.colors.primary500,
          },
        }),
      },
      Input: {
        props: {
          "data-testid": props.dataTestId,
          spellCheck: props.spellcheck,
        },
        style: ({ $theme, $disabled }) => ({
          ...(readonly
            ? {
                color: $theme.colors.contentPrimary,
                "-webkit-text-fill-color": $theme.colors.alpha600,
                cursor: "auto",
              }
            : $disabled
            ? {
                color: $theme.colors.contentPrimary,
                "-webkit-text-fill-color": $theme.colors.alpha300,
              }
            : {
                color: $theme.colors.alpha400,
                "-webkit-text-fill-color": $theme.colors.contentPrimary,
              }),
          "::placeholder": {
            color: $theme.colors.alpha400,
            fontWeight: 500,
            "-webkit-text-fill-color": $theme.colors.alpha400,
          },
          fontWeight: 500,
        }),
      },
      InputContainer: {
        style: {
          backgroundColor: "transparent",
        },
      },
    },
    props.overrides as Overrides<any> | undefined
  );

  return <BaseUITextArea inputRef={ref} {...props} disabled={disabled || readonly} overrides={overrides} />;
});
