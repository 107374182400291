import { mergeOverrides } from "baseui";
// eslint-disable-next-line no-restricted-imports
import type { RadioProps as BaseUIRadioProps } from "baseui/radio";
// eslint-disable-next-line no-restricted-imports
import type { Overrides } from "baseui/overrides";
import { Radio as BaseUIRadio } from "baseui/radio";
import React, { forwardRef } from "react";
import { marginUtil } from "../../utils";
import { useWidgets } from "../contexts";
export { ALIGN, RadioGroup } from "baseui/radio";

export type RadioProps = BaseUIRadioProps & {
  dataTestId?: string;
  readonly?: boolean;
};

export const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const { dataTestId, ...radioProps } = props;
  const { disableInputs, readonlyInputs } = useWidgets();
  const readonly = props.readonly ?? readonlyInputs?.(props);
  const disabled = props.disabled ?? disableInputs?.(props) ?? readonly;

  const overrides = mergeOverrides(
    {
      Root: {
        props: {
          "data-testid": dataTestId,
        },
      },
      RadioMarkOuter: {
        style: ({ $isFocusVisible, $checked, $theme, $isHovered, $error, $disabled }) => ({
          boxShadow: "none",
          outlineOffset: "1px",
          width: "16px",
          height: "16px",
          ...marginUtil(0),
          outline: $isFocusVisible && $checked ? `2px solid ${$theme.colors.borderFocus}` : "none",
          ...(readonly ? { cursor: "auto" } : {}),
          backgroundColor: $error
            ? $theme.colors.red500
            : ($isHovered || $checked) && !$disabled
            ? $theme.colors.neutralMax
            : $theme.colors.primary500,
        }),
      },
      RadioMarkInner: {
        style: ({ $theme, $checked }) => ({
          height: $checked ? "6px" : "14px",
          width: $checked ? "6px" : "14px",
          outline: "none",
          outlineOffset: "5px",
        }),
      },
    },
    // TypeScript can't determine that RadioOverrides interface is compatible with Overrides<any>
    // See https://github.com/microsoft/TypeScript/issues/15300
    props.overrides as Overrides<any> | undefined
  );

  return <BaseUIRadio inputRef={ref} data-testid={dataTestId} {...radioProps} disabled={disabled} overrides={overrides} />;
});
