import { mergeOverrides, useStyletron } from "baseui";
import type { StatefulMenuProps } from "baseui/menu";
import { StatefulMenu, StyledListItem } from "baseui/menu";
import type { Overrides } from "baseui/overrides";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { omit } from "lodash";
import type { ComponentProps } from "react";
import React, { forwardRef, useState } from "react";
import { DeleteIcon } from "../icons/DeleteIcon";
import KebabMenuIcon from "../icons/KebabMenuIcon";
import { marginHorizontal, paddingHorizontal, paddingVertical } from "../utils";
import { Row } from "./Row";
import { IconButton } from "./button/IconButton";

export type MoreMenuProps = {
  label: string;
  "data-testid"?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
} & StatefulMenuProps;

const MoreMenuListItem = forwardRef<HTMLLIElement, ComponentProps<typeof StyledListItem> & { header?: boolean }>((props, ref) => {
  const [_css, $theme] = useStyletron();
  return (
    <StyledListItem
      ref={ref}
      {...props}
      $style={{
        ...(props.item.header
          ? { cursor: "default" }
          : props.$disabled
          ? { color: $theme.colors.contentSecondary, filter: "grayscale(1)" }
          : {}),
        ...paddingHorizontal($theme.sizing.scale300),
        ...paddingVertical($theme.sizing.scale200),
        ...marginHorizontal($theme.sizing.scale200),
        borderRadius: $theme.sizing.scale200,
      }}
      ariaLabel={props.item.ariaLabel ?? props["aria-label"]}
    />
  );
});

export const MoreMenu = (props: MoreMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const overrides = mergeOverrides(
    {
      Option: {
        props: {
          overrides: {
            ListItemAnchor: {
              style: {
                color: "inherit",
                textDecoration: "inherit",
                fontWeight: "inherit",
              },
            },
            ListItem: {
              component: MoreMenuListItem,
            },
          },
        },
      },
    },
    props.overrides as Overrides<any> | undefined
  );

  return (
    <StatefulPopover
      {...{
        onClick: (event: React.MouseEvent) => {
          event.stopPropagation();
          event.preventDefault();
        },
        onOpen: () => {
          setIsOpen(true);
        },
        onClose: () => {
          setIsOpen(false);
        },
      }}
      placement={PLACEMENT.bottomRight}
      popoverMargin={0}
      content={({ close }) => (
        <StatefulMenu
          overrides={overrides}
          onItemSelect={({ event, item }) => {
            event?.stopPropagation();
            if (item.onSelect) {
              item.onSelect();
              close();
            }
          }}
          {...omit(props, ["label"])}
        />
      )}
    >
      <IconButton data-testid={props["data-testid"]} label={props.label} disabled={props.disabled} isSelected={isOpen}>
        {props.icon ?? <KebabMenuIcon direction="vertical" />}
      </IconButton>
    </StatefulPopover>
  );
};

export const MoreMenuRemoveLabel = (props: ComponentProps<typeof Row> & { $disabled?: boolean }) => {
  const [_css, $theme] = useStyletron();
  return (
    <Row
      $gap={$theme.sizing.scale200}
      {...props}
      $style={{ color: props.$disabled ? $theme.colors.red300 : $theme.colors.red500, ...props.$style }}
    >
      <DeleteIcon />
      {props.children ?? "Remove"}
    </Row>
  );
};
