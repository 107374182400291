import { StyledSvg } from "baseui/icon";
import React from "react";

const KebabMenuIcon = (props: React.ComponentProps<typeof StyledSvg> & { direction: "vertical" | "horizontal" }) => {
  const rotation = props.direction === "horizontal" ? 0 : 90;
  const viewBox = props.direction === "horizontal" ? "0 0 16 16" : "0 0 8 16";
  return (
    <StyledSvg viewBox={viewBox} {...props} fill="none">
      <path
        d="M2.6 6.40039C1.7164 6.40039 1 7.11679 1 8.00039C1 8.88399 1.7164 9.60039 2.6 9.60039C3.4836 9.60039 4.2 8.88399 4.2 8.00039C4.2 7.11679 3.4836 6.40039 2.6 6.40039ZM8 6.40039C7.1164 6.40039 6.4 7.11679 6.4 8.00039C6.4 8.88399 7.1164 9.60039 8 9.60039C8.8836 9.60039 9.6 8.88399 9.6 8.00039C9.6 7.11679 8.8836 6.40039 8 6.40039ZM13.4 6.40039C12.5164 6.40039 11.8 7.11679 11.8 8.00039C11.8 8.88399 12.5164 9.60039 13.4 9.60039C14.2836 9.60039 15 8.88399 15 8.00039C15 7.11679 14.2836 6.40039 13.4 6.40039Z"
        transform={`rotate(${rotation} 6 6)`}
      />
    </StyledSvg>
  );
};

export default KebabMenuIcon;
