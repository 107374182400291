import { mergeOverrides } from "baseui";
// eslint-disable-next-line no-restricted-imports
import type { CheckboxProps as BaseUICheckboxProps } from "baseui/checkbox";
// eslint-disable-next-line no-restricted-imports
import { Checkbox as BaseUICheckbox } from "baseui/checkbox";
import type { Overrides } from "baseui/overrides";
import React, { forwardRef } from "react";
import { expandBaseWebBorder, expandBorderColors, expandBorderRadii, expandBorderWidth } from "../../utils";
import { useWidgets } from "../contexts";
// eslint-disable-next-line no-restricted-imports
export { LABEL_PLACEMENT, STYLE_TYPE } from "baseui/checkbox";

export type CheckboxProps = BaseUICheckboxProps & {
  dataTestId?: string;
  readonly?: boolean;
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { dataTestId, ...checkboxProps } = props;
  const { disableInputs, readonlyInputs } = useWidgets();
  const readonly = props.readonly ?? readonlyInputs?.(props);
  const disabled = props.disabled ?? disableInputs?.(props) ?? readonly;

  const overrides = mergeOverrides(
    {
      Root: {
        style: {
          width: "fit-content",
          alignItems: "center",
          ...(readonly ? { cursor: "auto" } : {}),
        },
        props: {
          "data-testid": dataTestId,
        },
      },
      Checkmark: {
        style: ({ $theme, $isFocused, $checked, $isIndeterminate, $disabled, $error }) => {
          const tickColor = $disabled
            ? $theme.colors.tickMarkFillDisabled
            : $error
            ? $theme.colors.tickMarkFillError
            : $theme.colors.tickMarkFill;

          const indeterminate = encodeURIComponent(`
            <svg width="20" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 1H8.5" stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>
        `);

          const check = encodeURIComponent(`
          <svg width="16" height="16" viewBox="2 2 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2604 5.23964C12.5799 5.55916 12.5799 6.0772 12.2604 6.39672L7.35127 11.3058C7.03175 11.6253 6.51371 11.6253 6.19419 11.3058L3.73964 8.85127C3.42012 8.53175 3.42012 8.01371 3.73964 7.69419C4.05916 7.37467 4.5772 7.37467 4.89672 7.69419L6.77273 9.57019L11.1033 5.23964C11.4228 4.92012 11.9408 4.92012 12.2604 5.23964Z" fill="${tickColor}"/>
          </svg>
        `);

          return {
            ...expandBorderWidth("1px"),
            ...expandBorderRadii($theme.borders.radius200),
            backgroundOrigin: "context-box",
            height: $theme.sizing.scale600,
            width: $theme.sizing.scale600,
            outline: $isFocused ? `2px solid ${$theme.colors.borderFocus}` : undefined,
            outlineOffset: "1px",
            backgroundColor: $checked
              ? $disabled
                ? $theme.colors.alpha400
                : $theme.colors.alpha700
              : $disabled
              ? $theme.colors.alpha100
              : $theme.colors.neutralMin,
            backgroundImage: $isIndeterminate
              ? `url('data:image/svg+xml,${indeterminate}');`
              : $checked
              ? `url('data:image/svg+xml,${check}');`
              : (null as any),
            ":hover": {
              ...(!$checked && !$disabled && !$error ? expandBorderColors($theme.colors.neutralMax) : {}),
            },
          };
        },
      },
      Toggle: {
        style: ({ $isHovered, $theme, $checked, $disabled }) => ({
          boxShadow: $disabled ? undefined : $isHovered ? $theme.lighting.shadow600 : $theme.lighting.shadow500,
          height: "16px",
          width: "16px",
          backgroundColor: $theme.colors.neutralMin,
          marginLeft: "1px",
          ...expandBaseWebBorder({ ...$theme.borders.border100, borderColor: $disabled ? "transparent" : $theme.colors.alpha200 }),
          transform: $checked ? `translateX(16px)` : undefined,
        }),
      },
      ToggleTrack: {
        style: ({ $theme, $checked, $disabled }) => ({
          height: "20px",
          width: "36px",
          borderRadius: "100px",
          backgroundColor: $checked
            ? $disabled
              ? $theme.colors.primary300
              : $theme.colors.primary700
            : $disabled
            ? $theme.colors.primary100
            : $theme.colors.alpha50,
          boxShadow: $theme.lighting.shadowInputInset,
          border: `1px solid ${$disabled ? "transparent" : $theme.colors.alpha200}`,
          transition: `background-color ${$theme.animation.timing200}`,
        }),
      },
      ...(!props.children
        ? {
            // Label adds unnecessary padding we don't if there's no contents for the label
            Label: {
              style: {
                display: "none",
              },
            },
          }
        : {}),
    },
    // TypeScript can't determine that CheckboxOverrides interface is compatible with Overrides<any>
    // See https://github.com/microsoft/TypeScript/issues/15300
    props.overrides as Overrides<any> | undefined
  );

  return <BaseUICheckbox inputRef={ref} data-testid={dataTestId} {...checkboxProps} disabled={disabled} overrides={overrides} />;
});
