import { styled, useStyletron } from "baseui";
import { Theme } from "baseui/theme";
import { isObject, omit } from "lodash";
import type { ReactNode } from "react";
import React from "react";
import type { StyleObject } from "styletron-react";
import { ActionIcon } from "../icons/ActionIcon";
import { CloseIcon } from "../icons/CloseIcon";
import { ErrorInfoIcon } from "../icons/ErrorInfoIcon";
import { FailureCircleIcon } from "../icons/FailureCircleIcon";
import { InfoIcon } from "../icons/InfoIcon";
import KebabMenuIcon from "../icons/KebabMenuIcon";
import { ScheduleIcon } from "../icons/ScheduleIcon";
import { SuccessCircleIcon } from "../icons/SuccessCircleIcon";
import { WarningIcon } from "../icons/WarningIcon";
import { expandBorderRadii, paddingHorizontal, paddingVertical } from "../utils";
import { PlainButton } from "./button/PlainButton";

export interface CustomPillColor {
  color: string;
  backgroundColor: string;
}
export type PillColor =
  | "purple"
  | "green"
  | "yellow"
  | "blue"
  | "red"
  | "teal"
  | "light"
  | "primary"
  | "alpha"
  | "alpha200"
  | "production"
  | "success"
  | "error"
  | CustomPillColor;

const getPillColors = (theme: Theme, error?: boolean, color?: PillColor) => {
  let backgroundColor;
  let textColor;
  let hoverColor;
  if (error) {
    backgroundColor = theme.colors.red50;
    textColor = theme.colors.red500;
    hoverColor = theme.colors.red600;
  } else if (isObject(color)) {
    backgroundColor = color.backgroundColor;
    textColor = color.color;
    hoverColor = color.color;
  } else {
    switch (color) {
      case "purple": {
        backgroundColor = theme.colors.purple100;
        textColor = theme.colors.purple400;
        hoverColor = theme.colors.purple500;
        break;
      }
      case "yellow": {
        backgroundColor = theme.colors.yellow50;
        textColor = theme.colors.yellow600;
        hoverColor = theme.colors.yellow700;
        break;
      }
      case "green": {
        backgroundColor = theme.colors.green100;
        textColor = theme.colors.green600;
        hoverColor = theme.colors.green700;
        break;
      }
      case "red": {
        backgroundColor = theme.colors.red100;
        textColor = theme.colors.red600;
        hoverColor = theme.colors.red700;
        break;
      }
      case "blue": {
        backgroundColor = theme.colors.cyan50;
        textColor = theme.colors.cyan700;
        hoverColor = theme.colors.primary700;
        break;
      }
      case "teal": {
        backgroundColor = "#A2B9D736";
        textColor = "#404B5A";
        hoverColor = "#A2B9D736";
        break;
      }
      case "light": {
        backgroundColor = theme.colors.alpha100;
        textColor = theme.colors.alpha500;
        hoverColor = theme.colors.alpha600;
        break;
      }
      case "alpha200": {
        backgroundColor = theme.colors.alpha200;
        textColor = theme.colors.neutralMax;
        hoverColor = theme.colors.alpha700;
        break;
      }
      case "production": {
        backgroundColor = theme.colors.backgroundProduction;
        textColor = "#5634AE";
        break;
      }
      case "success": {
        backgroundColor = theme.colors.green50;
        textColor = theme.colors.green700;
        break;
      }
      case "error": {
        backgroundColor = theme.colors.red50;
        textColor = theme.colors.red700;
        break;
      }
      case "primary": {
        backgroundColor = theme.colors.primary200;
        textColor = theme.colors.primary700;
        hoverColor = theme.colors.primary300;
        break;
      }
      default:
      case "alpha": {
        backgroundColor = theme.colors.alpha100;
        textColor = theme.colors.alpha600;
        hoverColor = theme.colors.alpha700;
        break;
      }
    }
  }

  return {
    backgroundColor,
    color: textColor,
    hoverColor,
  };
};

export const PillContainer = styled<{ $style?: StyleObject; $error?: boolean; $color?: PillColor }, "span">(
  "span",
  ({ $theme, $color, $error, $style }) => {
    const { backgroundColor, color } = getPillColors($theme, $error, $color);

    return {
      ...paddingHorizontal($theme.sizing.scale100),
      ...paddingVertical(0),
      display: "inline-flex",
      alignItems: "center",
      ...expandBorderRadii("4px"),
      whiteSpace: "nowrap",
      ...$theme.typography.MonoLabelSmall,
      height: $theme.sizing.scale700,
      backgroundColor,
      color,
      ...$style,
    };
  }
);

export type PillProps = {
  children?: ReactNode;
  $style?: StyleObject;
  $color?: PillColor;
  error?: boolean;
  dismissable?: boolean;
  onDismiss?: (event: React.MouseEvent) => void;
} & Omit<JSX.IntrinsicElements["span"], "color">;

/** A small colored span serving as a discrete label */
export const Pill = (props: PillProps) => {
  const [_css, $theme] = useStyletron();
  const { hoverColor } = getPillColors($theme, props.error, props.$color);

  return (
    <PillContainer {...omit(props, ["error", "dismissable", "onDismiss"])} $error={props.error}>
      {props.children}
      {props.dismissable && (
        <PlainButton
          onClick={props.onDismiss}
          $style={{ display: "inline-flex", marginLeft: $theme.sizing.scale100, ":hover": { color: hoverColor, cursor: "pointer" } }}
        >
          <CloseIcon />
        </PlainButton>
      )}
    </PillContainer>
  );
};

/** A pill for consistency in how we tag things that are in beta */
export const BetaPill = (props: { children?: ReactNode; $style?: StyleObject }) => {
  return (
    <Pill $style={props.$style} $color="purple">
      {props.children ?? "beta"}
    </Pill>
  );
};

/** A pill for consistency in how we tag things that are newly released */
export const NewPill = (props: { children?: ReactNode; $style?: StyleObject }) => {
  return (
    <Pill $style={props.$style} $color="green">
      {props.children ?? "new"}
    </Pill>
  );
};

/** A pill showing info */
export const InfoPill = (props: { children: ReactNode; $style?: StyleObject }) => {
  const [_css, $theme] = useStyletron();

  return (
    <Pill $style={props.$style} $color="blue">
      <InfoIcon $color={$theme.colors.blue600} $style={{ marginRight: $theme.sizing.scale200 }} />
      {props.children}
    </Pill>
  );
};

/** A pill showing warning */
export const WarningPill = (props: { children: ReactNode; $style?: StyleObject }) => {
  const [_css, $theme] = useStyletron();

  return (
    <Pill $style={props.$style} $color="yellow">
      <WarningIcon color={$theme.colors.warning500} />
      {props.children}
    </Pill>
  );
};

export const SuccessPill = (props: { children: ReactNode; $style?: StyleObject }) => {
  const [_css, $theme] = useStyletron();

  return (
    <Pill $style={props.$style} $color="success">
      <SuccessCircleIcon color={$theme.colors.green500} $style={{ marginRight: $theme.sizing.scale200 }} />
      {props.children}
    </Pill>
  );
};

export const FailurePill = (props: { children: ReactNode; $style?: StyleObject }) => {
  const [_css, $theme] = useStyletron();

  return (
    <Pill $style={props.$style} $color="error">
      <FailureCircleIcon color={$theme.colors.negative500} $style={{ marginRight: $theme.sizing.scale200 }} />
      {props.children}
    </Pill>
  );
};

export const RunningPill = (props: { children: ReactNode; $style?: StyleObject; color?: string }) => {
  const [_css, $theme] = useStyletron();

  return (
    <Pill $style={props.$style} $color="blue">
      <span style={{ marginRight: $theme.sizing.scale200, marginTop: $theme.sizing.scale100 }}>
        <ActionIcon />
      </span>
      {props.children}
    </Pill>
  );
};

export const PendingPill = (props: { children: ReactNode; $style?: StyleObject }) => {
  const [_css, $theme] = useStyletron();

  return (
    <Pill $style={{ color: $theme.colors.black, ...props.$style }} $color="alpha">
      <KebabMenuIcon direction="horizontal" $style={{ marginRight: $theme.sizing.scale200, color: $theme.colors.black }} />
      {props.children}
    </Pill>
  );
};

export const RetryingPill = (props: { children: ReactNode; $style?: StyleObject }) => {
  const [_css, $theme] = useStyletron();

  return (
    <Pill $style={{ ...props.$style }} $color="yellow">
      <ErrorInfoIcon color={$theme.colors.yellow500} $style={{ marginRight: $theme.sizing.scale200 }} />
      {props.children}
    </Pill>
  );
};

export const ScheduledPill = (props: { children: ReactNode; $style?: StyleObject }) => {
  const [_css, $theme] = useStyletron();

  return (
    <Pill $style={{ color: $theme.colors.black, ...props.$style }} $color="alpha">
      <ScheduleIcon
        $style={{
          color: $theme.colors.black,
          marginRight: $theme.sizing.scale200,
          width: $theme.sizing.scale550,
          height: $theme.sizing.scale550,
        }}
      />
      {props.children}
    </Pill>
  );
};

export const StaffPreviewPill = (props: { children?: ReactNode; $style?: StyleObject }) => {
  return <BetaPill>{props.children ?? "STAFF PREVIEW"}</BetaPill>;
};
