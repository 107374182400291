import { mergeOverrides } from "baseui";
import { DeleteAlt } from "baseui/icon";
import type { Overrides } from "baseui/overrides";
import type { TagProps } from "baseui/tag";
import { omit } from "lodash";
// eslint-disable-next-line no-restricted-imports
import type { SelectProps as BaseUISelectProps } from "baseui/select";
// eslint-disable-next-line no-restricted-imports
import { Select as BaseUISelect, SIZE as SelectSize } from "baseui/select";
// eslint-disable-next-line no-restricted-imports, baseui/no-deep-imports
import { normalizeOptions } from "baseui/select/utils";
import React, { forwardRef, type ReactNode } from "react";
import { expandBorderWidth } from "../../utils";
import { useWidgets } from "../contexts";
import { Pill } from "../Pill";
import { ToolTip } from "../ToolTip";
// eslint-disable-next-line no-restricted-imports
export { SIZE as SelectSize, TYPE as SelectType, StyledDropdownListItem, StyledValueContainer } from "baseui/select";
// eslint-disable-next-line no-restricted-imports
export type { OnChangeParams, OptgroupsT, Option, SelectOverrides, STATE_CHANGE_TYPE, Value } from "baseui/select";
export const SelectUtils = { normalizeOptions };

export interface TruncateStartProps {
  maxLength?: number;
  alwaysFirst?: number;
  useFrontEllipsis?: boolean;
}

export interface TruncateOptions {
  truncateSelectedValues?: TruncateStartProps;
  truncateValues?: TruncateStartProps;
}

export type SelectProps = BaseUISelectProps & {
  dataTestId?: string;
  readonly?: boolean;
  disabledMessage?: ReactNode;
  truncateOptions?: TruncateOptions;
};

/**
 * BaseUI re-export for minimal selects because we need to pass overrides to style the borders right. Whereever you want to use an <Select /> from baseui, you should use this instead.
 */
export const Select = forwardRef<HTMLInputElement, SelectProps>((props, ref) => {
  const { dataTestId, disabledMessage, truncateOptions, ...selectProps } = props;
  const { disableInputs, readonlyInputs } = useWidgets();
  const disabled = props.disabled ?? disableInputs?.(props);
  const readonly = props.readonly ?? readonlyInputs?.(props);

  // baseui defaults are different, but we find we want the opposites
  selectProps.clearable ??= false;
  selectProps.closeOnSelect ??= true;

  let select = (
    <BaseUISelect
      controlRef={props.controlRef ?? ref}
      {...selectProps}
      disabled={disabled || readonly}
      overrides={mergeOverrides(
        {
          ControlContainer: {
            style: ({ $theme, $isFocused, $disabled }) => {
              return {
                ...expandBorderWidth("1px"),
                boxShadow: $disabled
                  ? undefined
                  : $isFocused
                  ? `inset 0 0 0 1px ${$theme.colors.borderFocus}`
                  : $theme.lighting.shadowInputInset,
                ...(readonly
                  ? {
                      color: $theme.colors.contentPrimary,
                      cursor: "auto",
                    }
                  : {}),
              };
            },
          },
          Input: {
            props: {
              "data-testid": dataTestId,
            },
            style: ({ $theme }) => ({
              ...(readonly
                ? {
                    color: $theme.colors.contentPrimary,
                    "-webkit-text-fill-color": $theme.colors.contentPrimary,
                  }
                : {}),
            }),
          },
          Tag: {
            component: (tagProps: TagProps) => <MultiSelectValue {...tagProps} truncateStart={truncateOptions?.truncateSelectedValues} />,
          },
          OptionContent: {
            style: {
              fontWeight: "normal",
            },
          },
          ClearIcon: <DeleteAlt size={16} />,
          SelectArrow: {
            style: ({ $theme, $isFocused }) => ({
              color: $isFocused ? $theme.colors.contentPrimary : $theme.colors.primary500,
            }),
          },
          SearchIcon: {
            props: {
              overrides: {
                Svg: {
                  style: (props: any) => ({
                    color: props.$theme.colors.primary500,
                  }),
                },
              },
            },
          },
          ValueContainer: {
            style: (props) => {
              const { $theme, $size, $multi } = props;
              const label = props.children[0]?.props?.children;
              const narrowLeftPadding = $multi || !label || typeof label != "string";
              const inputPadding = (
                {
                  [SelectSize.mini]: {
                    paddingTop: $theme.sizing.scale100,
                    paddingBottom: $theme.sizing.scale100,
                    paddingLeft: narrowLeftPadding ? $theme.sizing.scale100 : $theme.sizing.scale200,
                  },
                  [SelectSize.compact]: {
                    paddingTop: $theme.sizing.scale100,
                    paddingBottom: $theme.sizing.scale100,
                    paddingLeft: narrowLeftPadding ? $theme.sizing.scale100 : $theme.sizing.scale200,
                  },
                  [SelectSize.default]: {
                    paddingTop: $theme.sizing.scale200,
                    paddingBottom: $theme.sizing.scale200,
                    paddingLeft: narrowLeftPadding ? $theme.sizing.scale200 : $theme.sizing.scale300,
                  },
                  [SelectSize.large]: {
                    paddingTop: $theme.sizing.scale550,
                    paddingBottom: $theme.sizing.scale550,
                  },
                } as Record<string, any>
              )[$size];

              return {
                gap: $multi ? $theme.sizing.scale100 : undefined,
                ...inputPadding,
              };
            },
          },
        },
        // TypeScript can't determine that SelectOverrides interface is compatible with Overrides<any>
        // See https://github.com/microsoft/TypeScript/issues/15300
        props.overrides as Overrides<any> | undefined
      )}
    />
  );

  if (props.disabled && disabledMessage) {
    select = (
      <ToolTip label={props.disabledMessage}>
        <div>{select}</div>
      </ToolTip>
    );
  }

  return select;
});

export const TruncateStart = (props: { text: string; options: TruncateStartProps }) => {
  const {
    text,
    options: { maxLength, alwaysFirst },
  } = props;

  if (maxLength && !alwaysFirst && text.length <= maxLength) {
    return <span>{text}</span>;
  }

  let truncatedText = text.slice(alwaysFirst ?? 0);
  truncatedText = truncatedText.slice(-(maxLength ?? truncatedText.length));

  return <span title={text}>...{truncatedText}</span>;
};

/** Adapter from baseui's Tag to our Pill component */
const MultiSelectValue = (props: TagProps & { truncateStart?: TruncateStartProps }) => {
  const { truncateStart, ...tagProps } = props;

  return (
    <Pill
      dismissable={!props.disabled}
      onDismiss={(event) => props.onActionClick?.(event as any)}
      {...(omit(tagProps, ["closeable", "onActionClick", "children"]) as any)}
    >
      {truncateStart ? <TruncateStart text={tagProps.children as string} options={truncateStart} /> : tagProps.children}
    </Pill>
  );
};
