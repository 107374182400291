import { styled } from "baseui";
import { colors } from "baseui/tokens";

export const Caption = styled("p", ({ $theme }) => ({
  ...$theme.typography.caption,
  color: colors.gray600,
}));

export const CaptionSmall = styled("p", ({ $theme }) => ({
  ...$theme.typography.ParagraphXSmall,
  color: colors.gray600,
}));
